import React from "react";
import { Link } from "gatsby";
export default function Footer() {
  return (
    <div className="footer">
      <div className="contactDetails">
        <h3>CONTACTGEGEVENS</h3>
        <p>
          TEL:<span className="span1">31+ 655 568 904</span>
        </p>
        <p>
          EMAIL:<span>info@vdbrecherche.nl</span>
        </p>
        <p>
          KVK:<span className="span2">83813403</span>
        </p>
        <p>
          SVPB:<span>264942.267333717</span>
        </p>
      </div>
      <div className="footerExtra">
        <div className="top">
          <p>© VDB CONSULTING 2022</p>
        </div>
        <div className="bottom">
          <Link href={`/static/algemene-voorwaarden.pdf`}>Voorwaarden</Link>
          <Link href={`/static/klachtenregeling.pdf`}>Klachtenregeling</Link>
          <Link href={`/static/privacyverklaring.pdf`}>Privacy</Link>
        </div>
      </div>
    </div>
  );
}
