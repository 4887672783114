import * as React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";

import icon1 from "../images/icons/advice.png";
import icon2 from "../images/icons/bemiddeling.png";
import icon3 from "../images/icons/lezingen.png";
import icon4 from "../images/icons/research.png";

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>VDB Consulting</title>
        <meta name="format-detection" content="telephone=no"></meta>
      </Helmet>
      <div className="homePage">
        <div className="showcase">
          <div className="introText">
            <h1>
              <span>VDB</span> Consulting
            </h1>
            <p>
              VDB Consulting/VDB recherchebureau i.o. is een onafhankelijk
              landelijk opererend adviesbureau op het gebied van bedrijfs
              onregelmatigheden en particuliere onderzoeken. Daarnaast is VDB
              Consulting/VDB recherchebureau i.o. in ontwikkeling om per
              01-08-2024 een recherche-afdeling toe te voegen aan haar
              activiteiten.
            </p>
          </div>
        </div>

        <div className="services">
          <h2>
            IK KAN U <span>HELPEN</span> DOOR MIDDEL VAN
          </h2>
          <div className="servicesShort">
            <Fade bottom>
              <div className="service">
                <img src={icon1} alt="" />
                <h3>Advies</h3>
                <p>
                  Advies op het gebied van bedrijfs- en particuliere onderzoeken
                </p>
              </div>
            </Fade>
            <Fade bottom>
              <div className="service">
                <img src={icon4} alt="" />
                <h3>Onderzoek</h3>
                <p>Op het gebied van bedrijfs- en particuliere onderzoeken</p>
              </div>
            </Fade>
            <Fade bottom>
              <div className="service">
                <img src={icon3} alt="" />
                <h3>Lezingen</h3>
                <p>Lezingen over het werk van een familierechercheur</p>
              </div>
            </Fade>
            <Fade bottom>
              <div className="service">
                <img src={icon2} alt="" />
                <h3>Bemiddeling</h3>
                <p>Bemiddeling bij arbeidsgerelateerde conflicten</p>
              </div>
            </Fade>
          </div>
          <Link to="/diensten">Bekijk alle diensten</Link>
        </div>

        <div className="contactPopup">
          <h3>KAN IK IETS VOOR U BETEKENEN?</h3>
          <h3 className="blueColor">
            KOM MEER TE WETEN IN EEN VRIJBLIJVEND GESPREK!
          </h3>
          <p>
            In een kort (online) gesprek bespreken wij uw case en geef ik advies
            welke diensten of vervolgstappen u kunt nemen.{" "}
          </p>
          <Link to="/contact">Contact opnemen</Link>
        </div>

        <div className="aboutContainer">
          <Fade left>
            <div className="leftSection" />
          </Fade>

          <Fade right>
            <div className="rightSection">
              <h2>
                <span>OVER VDB</span> CONSULTING
              </h2>
              <p>
                VDB Consulting/VDB Recherchebureau i.o. heeft een brede ervaring
                op het gebied van <span>adviseren</span>,{" "}
                <span>recherchewerkzaamheden</span> en <span>bemiddeling</span>.
                Bij VDB Consulting/VDB Recherchebureau i.o. kunt u rekenen op{" "}
                <span>integriteit</span>, <span>betrouwbaarheid </span>
                en <span>professionaliteit</span>.{" "}
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
